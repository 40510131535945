export const CategoriesData = [
    {
        _id: '1',
        title: 'Romantic',
    },
    {
        _id: '2',
        title: 'Action',
    },
    {
        _id: '3',
        title: 'Horror',
    },
    {
        _id: '4',
        title: 'Comedy',
    },
    {
        _id: '5',
        title: 'Adventure',
    },
    {
        _id: '6',
        title: 'Sports',
    },
    {
        _id: '7',
        title: 'Fantasy',
    },
    {
        _id: '8',
        title: 'Musicals',
    },
    {
        _id: '9',
        title: 'Drama',
    },
    {
        _id: '10',
        title: 'Thriller',
    },
    {
        _id: '11',
        _id: '11',
        title: 'Western',
    },
    {
        _id: '12',
        title: 'Historical',
    },
    {
        _id: '13',
        title: 'Science',
    },
    {
        _id: '14',
        title: 'Mystery',
    },
];